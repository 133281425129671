import React from 'react';
import { Generic, JSONLD } from 'react-structured-data';

import buildBreadcrumbListItem from './buildBreadcrumbListItem';
import routes from '../../config/routes';
import { STRUCTURED_DATA_HOMEPAGE_BREADCRUMB_ITEM } from './constants';

const ImprintPageBreadcrumb = () => {
  const imprintPageListItem = buildBreadcrumbListItem({ name: 'Imprint', position: 2, url: routes.imprint });

  const schema = {
    itemListElement: [STRUCTURED_DATA_HOMEPAGE_BREADCRUMB_ITEM, imprintPageListItem],
  };

  return (
    <JSONLD dangerouslyExposeHtml={true}>
      <Generic jsonldtype="BreadcrumbList" schema={schema} />
    </JSONLD>
  );
};

export default ImprintPageBreadcrumb;
