import styled from '../../utils/styling/styled';

import Heading from '../Heading';
import styleUtils from '../../utils/styling/styleUtils';

export const Title = styled(Heading)`
  ${styleUtils.font.weight.bold};

  color: ${props => props.theme.color.green};
  font-size: 6rem;
  line-height: 1;
  text-transform: uppercase;
`;
