import React from 'react';

import SectionStaticPageContent from '../Section/SectionStaticPageContent';
import StaticPageAddress from '../StaticPageItems/StaticPageAddress';
import StaticPageHeadline from '../StaticPageItems/StaticPageHeadline';
import StaticPageSection from '../StaticPageItems/StaticPageSection';
import StaticPageText from '../StaticPageItems/StaticPageText';
import StaticPageTextLink from '../StaticPageItems/StaticPageTextLink';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  footer: ReactNode;
};

const ImprintPage = ({ footer }: Props) => {
  return (
    <>
      <SectionStaticPageContent isTitleSection={true} contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={25}>
        <S.Title>
          Im
          <br />
          print
        </S.Title>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={25}>
        <StaticPageSection>
          <StaticPageHeadline level={3}>Angaben gemäß §5 TMG:</StaticPageHeadline>

          <StaticPageAddress>
            Jan Kath Design GmbH
            <br />
            Friederikastr. 148
            <br />
            44789 Bochum
          </StaticPageAddress>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={4}>Vertreten durch:</StaticPageHeadline>

          <StaticPageText>
            Jan Kath
            <br />
            David Kath
            <br />
            Ruth Kath
          </StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={4}>Kontakt:</StaticPageHeadline>

          <StaticPageText>
            T: +49 (0) 234 9412344
            <br />
            F: +49 (0) 234 9412390
            <br />
            <StaticPageTextLink url="mailto:info@jan-kath.com">info@jan-kath.com</StaticPageTextLink>
          </StaticPageText>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={25}>
        <StaticPageSection>
          <StaticPageHeadline level={4}>Registereintrag:</StaticPageHeadline>

          <StaticPageText>
            Eintragung im Handelsregister.
            <br />
            Registergericht: AG Bochum
            <br />
            Registernummer: HRB 7374
          </StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={4}>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:</StaticPageHeadline>

          <StaticPageText>DE813501983</StaticPageText>
        </StaticPageSection>

        <StaticPageSection>
          <StaticPageHeadline level={3}>Gestaltung:</StaticPageHeadline>

          <StaticPageText>
            Oktober Kommunikationsdesign GmbH
            <br />
            Willy-Brandt-Platz 5-7
            <br />
            44787 Bochum
            <br />
            <StaticPageTextLink url="https://www.oktober.de">www.oktober.de</StaticPageTextLink>
          </StaticPageText>

          <br />

          <StaticPageHeadline level={3}>Umsetzung:</StaticPageHeadline>

          <StaticPageText>
            Jan Kath Design GmbH
            <br />
            Friederikastr. 148
            <br />
            44789 Bochum
          </StaticPageText>
        </StaticPageSection>
      </SectionStaticPageContent>

      <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={75}>
        <StaticPageSection>
          <StaticPageHeadline level={3}>Haftung für Inhalte</StaticPageHeadline>
          <StaticPageText>
            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
            können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
            nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
            übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
            Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
            bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
            Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            Für Inhalte verlinkter Seiten wird keine Haftung übernommen. Für den Inhalt der verlinkten Seiten sind ausschließlich deren
            Betreiber verantwortlich.
          </StaticPageText>
        </StaticPageSection>
      </SectionStaticPageContent>

      <StaticPageSection>
        <SectionStaticPageContent contentPositionX="flex-start" contentPositionY="flex-start" sectionWidth={75}>
          <StaticPageHeadline level={3}>Datenschutz</StaticPageHeadline>

          <StaticPageText>
            Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sog.
            „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie
            ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server
            von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite, wird
            Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
            Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server
            von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen
            benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit
            der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im
            Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
            Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie
            jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen
            können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten
            (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem
            folgenden Link{' '}
            <StaticPageTextLink url="http://tools.google.com/dlpage/gaoptout?hl=de">
              (http://tools.google.com/dlpage/gaoptout?hl=de)
            </StaticPageTextLink>
            verfügbare Browser-Plugin herunterladen und installieren.
          </StaticPageText>
        </SectionStaticPageContent>
      </StaticPageSection>
      {footer}
    </>
  );
};

export default ImprintPage;
