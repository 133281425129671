import React from 'react';

import Heading from '../../components/Heading';
import ImprintPage from '../../components/ImprintPage';
import ImprintPageBreadcrumb from '../../utils/seo/ImprintPageBreadcrumb';
import SEOContainer from '../Layout/SEO';
import Section from '../../components/Heading/Section';
import SectionFooterContactDefaultContainer from '../Section/SectionFooterContactDefault';
import buildContentPageUrl from '../../utils/url/buildContentPageUrl';
import routes from '../../config/routes';
import theme from '../../utils/styling/theme';

// Types

type Props = {};

const ImprintPageContainer = (_props: Props) => {
  const canonicalUrl = buildContentPageUrl(routes.imprint);

  const footer = <SectionFooterContactDefaultContainer backgroundColor={theme.color.grey} textColor={theme.color.white} />;

  return (
    <>
      {/* SEO */}
      <ImprintPageBreadcrumb />
      <SEOContainer canonicalUrl={canonicalUrl} title="Imprint" openGraphTitle="Imprint" />

      <Heading isVisuallyHidden={true}>Imprint</Heading>

      <Section hasNoTag={true}>
        <ImprintPage footer={footer} />
      </Section>
    </>
  );
};

export default ImprintPageContainer;
