import React from 'react';

import ImprintPageContainer from '../containers/ImprintPage';
import LayoutContainer from '../containers/Layout';

const ImprintPagePage = () => {
  return (
    <LayoutContainer>
      <ImprintPageContainer />
    </LayoutContainer>
  );
};

export default ImprintPagePage;
